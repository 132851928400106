import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { isMobile } from '@constants/platform';

import { RootStackNavigator } from './types';

const MAIN_NAV_LINKING = isMobile
  ? {
      Main: {
        screens: {
          Home: '/home',
          Explore: '/explore',
          GameBuilder: '/game-builder',
          MyProfile: '/profile',
        },
      },
    }
  : {
      Home: '',
      Explore: '/explore',
      GameBuilder: '/game-builder',
      MyProfile: '/profile',
    };

const ACCOUNT_SETTINGS_LINKING = isMobile
  ? {
      AccountSettings: '/account-settings',
      ChangePassword: '/change-password',
      ChangeName: '/change-name',
      ChangeUsername: '/change-username',
      ChangeBio: '/change-bio',
      ChangeEmail: '/change-email',
      DeleteAccount: '/delete-account',
    }
  : {
      AccountSettings: {
        path: '/account-settings',
        screens: {
          Overview: '/overview',
          ChangePassword: '/change-password',
          ChangeName: '/change-name',
          ChangeUsername: '/change-username',
          ChangeBio: '/change-bio',
          ChangeEmail: '/change-email',
          DeleteAccount: '/delete-account',
        },
      },
    };

export const linking: LinkingOptions<RootStackNavigator> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      GamePage: '/game/:name',
      Settings: '/settings',
      ...MAIN_NAV_LINKING,
      ...ACCOUNT_SETTINGS_LINKING,
      ValidateCode: '/validate-code',
      Login: '/login',
      Register: '/register',
      ForgotPassword: '/forgot-password',
      ResetPassword: '/reset-password',
      Follows: '/follows',
      UserProfile: '/profile/:userName',
      AboutGATO: '/about',
      UploadGame: '/upload-game',
      TermsAndConditions: '/terms-and-conditions',
      GameWebView: '/ingame/', // We need to add the trailing slash because is the only way to preserve the query params in AWS Amplify
      PolicyOfPrivacy: '/privacy-policy',
      Token: '/token',
      ChangeAvatarFirstStep: '/change-avatar',
      Notifications: '/notifications',
      Search: '/search/:search',
      Dashboard: '/dashboard',
      DeleteAccountWithoutLogin: '/delete-account',
      NotFound: '*',
    },
  },
};
