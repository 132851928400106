import React from 'react';
import { ImageBackground, Pressable, View } from 'react-native';
import { CommonActions, useNavigation } from '@react-navigation/core';
import Lottie from 'lottie-react-native';

import CustomText from '@atoms/CustomText';
import { moderateScale } from '@utils/scaling';
import { setGameIdHandler, useStore } from '@store/index';
import { useTranslations } from '@hooks/useTranslation';
import { isWebResponsive } from '@constants/platform';

import { styles } from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const lottie = require('../../../assets/lotties/empty_state_gato_ovni.json');

interface LikedItemProps {
  game: Game;
  index: number;
}

const LikedItem = ({ game, index }: LikedItemProps) => {
  const navigation = useNavigation();
  const { id, name, principalImage, gameSlug, description_en } = game;
  const setGameId = useStore(setGameIdHandler);

  const handlePress = () => {
    setGameId(id);

    const navigateAction = CommonActions.navigate({
      name: 'GamePage',
      params: {
        name: gameSlug,
      },
      key: `GamePage/${gameSlug}`,
    });
    navigation.dispatch(navigateAction);
  };

  return (
    <Pressable onPress={handlePress}>
      <View style={styles.item}>
        <ImageBackground style={styles.image} source={{ uri: principalImage }}>
          {/* <View style={styles.tag}>
            <GameTag color="white" text="Featured" textColor="darkGrey" bigText />
          </View> */}
        </ImageBackground>
        <View style={styles.description}>
          <CustomText align="left" size="medium" weight="interSemi">
            {name}
          </CustomText>
        </View>
      </View>
    </Pressable>
  );
};

interface LikedGameListProps {
  games: GameItem[];
  isOwnProfile?: boolean;
  name?: string;
}

export const LikedGameList = ({ games, isOwnProfile, name }: LikedGameListProps) => {
  const i18n = useTranslations();

  const titleFontSize = isWebResponsive ? 'lmedium' : 'mlarge';
  const emptyContentFontSize = isWebResponsive ? 'lmedium' : 'big';

  return (
    <View style={styles.container}>
      <CustomText
        customStyle={styles.title}
        align="left"
        weight="interSemi"
        color="greySix"
        size={titleFontSize}>
        {i18n.t('profile.liked_games')}
      </CustomText>

      {games?.length === 0 ? (
        <View style={styles.emptyContainer}>
          <Lottie source={lottie} autoPlay loop style={styles.lottie} />

          <CustomText color="greySix" weight="interSemi" size={emptyContentFontSize}>
            {isOwnProfile
              ? i18n.t('profile.empty_likes_own')
              : `${name} ${i18n.t('profile.empty_likes_other')}`}
          </CustomText>
        </View>
      ) : (
        <View style={styles.itemContainer}>
          {games?.map((item, index) => (
            <LikedItem key={item.id} game={item.game} index={index} />
          ))}
        </View>
      )}
    </View>
)};

export interface GameItem {
  id: number;
  user: number;
  game: Game;
}

export interface Game {
  studio: any;
  id: number;
  name: string;
  gameSlug: string;
  principalImage: string;
  description: string;
  sensitiveMaterial: boolean;
  controlType: string;
  operatingSystem: string;
  status: string;
  platform: string;
  stars: null;
  points: null;
  pointsQuantity: null;
  numberOfPlayers: number;
  recomendedYear: null;
  createdDate: string;
  carrouselPrincipal: boolean;
  principalOrder: null;
  carrouselRecomended: boolean;
  recomendedOrder: number;
  carrouselSelected: boolean;
  selectedOrder: number;
  carrouselLikes: boolean;
  likesOrder: number;
}
