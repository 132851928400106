import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';
import { isWebResponsive } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    zIndex: 3,
    width: moderateScale(110),
    height: moderateScale(110),
    borderRadius: moderateScale(55),
    alignSelf: 'center',
    marginTop: moderateScale(20),
    overflow: 'hidden'
  },
  background: { width: '100%', height: '100%' },
  cat: { width: '100%', height: '100%', marginTop: 30 },
  greyBackground: {
    backgroundColor: Colors.greySix
  },
  avatarModalContainer: {
    position: 'absolute',
    width: '100%',
    zIndex: 999,
    bottom: 0,
    left: 0,
    height: '100%',
    backgroundColor: Colors.greyFour + '80',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatarAnimatedView: {
    width: '100%',
    zIndex: 999,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalCat: { width: moderateScale(300), height: moderateScale(300), opacity: 1 },
  modalCatBackground: { width: moderateScale(300), height: moderateScale(300) },
  online: {
    borderWidth: 3,
    borderColor: Colors.greenParrot,
    borderStyle: 'solid',
    boxShadow: '0 0 5px 2px #62FF4A',
  },
  onlineIndicator: {
    zIndex: 4,
    position: 'absolute',
    bottom: 0,
    right: 5,
    transform: 'translate(-20%, -15%)',
    backgroundColor: Colors.greenParrot,
    width: isWebResponsive ? '18px' : '25px',
    height: isWebResponsive ? '18px' : '25px',
    borderRadius: '50%',
    borderWidth: 2,
    borderColor: Colors.black,
    borderStyle: 'solid',
  },
  isInNavigationBar: {
    bottom: 0,
    right: 0,
    width: '10px',
    height: '10px',
    borderWidth: 1,
  }
});
