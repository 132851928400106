import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { isWebResponsive } from '@constants/platform';
import { Colors } from '@theme';

import { socialBackground } from '../../../theme/colors';


export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: { marginBottom: moderateScale(20) },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: moderateScale(8),
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    padding: moderateScale(16),
    borderRadius: moderateScale(12),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: Colors.blackTwo,
    alignItems: 'center',
    gap: moderateScale(10),
    width: isWebResponsive ? '90vw' : '15vw',
    height: '100px',
  },
  gradient: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    borderRadius: moderateScale(10)
  },
  text: { zIndex: 3, position: 'absolute', paddingVertical: 16, paddingHorizontal: 14, bottom: 0 },
  tooltipContainer: {
    position: 'absolute',
    maxWidth: moderateScale(450),
    zIndex: 5,
    top: 40
  },
  tooltipBackground: {
    padding: 20,
    backgroundColor: socialBackground,
    borderRadius: 20
  },
  tooltipIndicator: {
    position: 'absolute',
    width: 14,
    height: 14,
    top: -7,
    left: 40,
    backgroundColor: socialBackground,
    transform: [{ rotate: '45deg' }]
  },
  tooltipTitle: {
    flexDirection: 'row',
    marginBottom: 10
  },
  icon: { marginRight: 6 },
  emptyContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lottie: {
    width: moderateScale(207),
  },
  image: {
    width: '90px',
    height: '90px',
    resizeMode: 'cover',
    borderRadius: 16,
    overflow: 'hidden',
  },
  description: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  creator: {
    marginTop: moderateScale(6),
  },
});
