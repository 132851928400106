import React, { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Keyboard, Pressable, View } from 'react-native';
import { AxiosError } from 'axios';
import { CommonActions } from '@react-navigation/core';

import EmailInputIcon from '@assets/svg/EmailInputIcon';
import PasswordInputIcon from '@assets/svg/PasswordInputIcon';
import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { useStatusBar } from '@hooks/useStatusBar';
import { useTranslations } from '@hooks/useTranslation';
import { LoginParams } from '@interfaces/auth';
import Field from '@molecules/Field';
import { Layout } from '@organisms/Layout';
import { useLogin } from '@services/useLogin';
import { setAuthToken, setRefreshToken } from '@utils/session';
import toast from '@utils/toast';
import { setAuthTokenHandler, useStore } from '@store/index';
import { useResendValidateCode } from '@services/useResendValidateCode';
import { isDesktop } from '@constants/platform';
import DesktopCenteredContainer from '@atoms/DesktopCenteredContainer';
import { Colors } from '@theme';
import SocialLoginButtons from '@molecules/SocialLoginButtons';

import DividerWithText from '../DividerWithText';

import Onboarding from './Onboarding/index';
import { styles } from './styles';
import { LoginProps } from './types';
import LoginHeader from './Header';

export const Login = ({ navigation }: LoginProps) => {
  // Refs
  const refInputEmail = useRef(null);
  const refInputPassword = useRef(null);

  // Hooks
  useStatusBar('light');
  const i18n = useTranslations();
  const {
    control,
    getValues,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginParams>();
  const isEmpty = !watch('emailOrUsername') || !watch('password');

  const login = useLogin();
  const resendCodeVerifyEmail = useResendValidateCode();
  const setStoreToken = useStore(setAuthTokenHandler);

  const [isComingFromGameBuilder, setIsComingFromGameBuilder] = useState(false);

  useEffect(() => {
    const routes = navigation.getState()?.routes;

    const currentRoute = routes.findIndex((route) => route.name === 'Login');
    const previousRoute = routes[currentRoute - 1];

    if (previousRoute && previousRoute.name === 'GameBuilder') {
      setIsComingFromGameBuilder(true)
    }
  }, []);

  // Functions
  const handleRegister = () => {
    navigation.navigate('Register');
  };

  const handleForgotPassword = () => {
    navigation.navigate('ForgotPassword');
  };

  const handleDeleteAccount = () => {
    navigation.navigate('DeleteAccountWithoutLogin');
  };

  function onSubmit(values: LoginParams) {
    Keyboard.dismiss();

    login.mutate(
      { ...values, remember: true },
      {
        onSuccess: (response) => {
          setAuthToken(response.access_token);
          setStoreToken(response.access_token);
          setRefreshToken(response.refreshToken);
          CommonActions.reset({
            routes: [{ name: 'Home' }],
          });
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            const errorCode = error?.response?.data.errorCode;
            const message = i18n.t(error?.response?.data.translate);
            if (errorCode === 'ERROR_NOT_VERIFIED') {
              resendCodeVerifyEmail.mutate(
                { emailOrUsername: values.emailOrUsername },
                {
                  onSuccess: (response) => {
                    navigation.navigate('ValidateCode', {
                      userId: response.id,
                      userEmail: response.mail,
                      type: 'VERIFY',
                      password: values.password,
                    });
                  },
                }
              );
            }
            toast.danger({
              title: message,
              icon: 'error',
            });
          }
        },
      }
    );
  }

  return (
    <Layout
      withScroll
      withBackgroundGleam
      verticallyCentered={isDesktop}
      backgroundColor={isDesktop ? Colors.blackThree : undefined}>
      <View style={[styles.container]}>
        <DesktopCenteredContainer style={styles.desktopForm}>
          {/* Form */}
          <View>
            <LoginHeader showGameBuilderBanner={isComingFromGameBuilder} />
            <Field
              icon={EmailInputIcon}
              autoFocus
              name="emailOrUsername"
              type="email-address"
              control={control}
              placeholder={i18n.t('login.email_placeholder').toString()}
              getValues={getValues}
              rules={{
                required: i18n.t('login.required').toString(),
              }}
              error={errors}
              customRef={refInputEmail}
              containerStyles={styles.input}
            />
            <Field
              icon={PasswordInputIcon}
              name="password"
              control={control}
              placeholder={i18n.t('login.password_placeholder').toString()}
              rules={{
                required: i18n.t('login.required').toString(),
                minLength: {
                  value: 8,
                  message: i18n.t('login.password_min_error'),
                },
              }}
              secure
              getValues={getValues}
              error={errors}
              customRef={refInputPassword}
              containerStyles={styles.input}
            />
            <Pressable style={{ marginBottom: 16 }} hitSlop={10} onPress={handleForgotPassword}>
              <CustomText weight="interBold" customStyle={styles.underline} color="greySix" size="medium">
                {i18n.t('login.forgot_password')}
              </CustomText>
            </Pressable>
            <Button
              disabled={isEmpty}
              loading={login.isLoading}
              customStyle={styles.button}
              customTextStyle={styles.textButton}
              onPress={handleSubmit(onSubmit)}>
              {i18n.t('login.button_action')}
            </Button>
          </View>

          {/* Divider OR */}
          <DividerWithText />

          <View style={styles.socialLogin}>
            <CustomText color="greySix" size="medium" weight="interBold" customStyle={{ marginBottom: 16 }}>
              {i18n.t('login.continue_with')}
            </CustomText>
            <SocialLoginButtons />
          </View>

          {/* Footer */}
          <View style={styles.footer}>
            <CustomText color="textInputDefault" size="medium" weight="interBold">
              {`${i18n.t('login.dont_have_account')}  `}
            </CustomText>
            <Pressable hitSlop={10} onPress={handleRegister}>
              <CustomText customStyle={styles.underline} size="medium" color="limeGreen" weight="interBold">
                {i18n.t('login.register')}
              </CustomText>
            </Pressable>
          </View>

          {/* Delete account */}
          <Pressable hitSlop={10} onPress={handleDeleteAccount}>
            <CustomText
              weight="interBold"
              customStyle={styles.underline}
              color="textInputDefault"
              size="medium">
              {i18n.t('account_settings.delete_account')}
            </CustomText>
          </Pressable>
        </DesktopCenteredContainer>
      </View>
    </Layout>
  );
};

const OnboardingSection = () => (
  <View style={styles.onboarding}>
    <Onboarding />
  </View>
);
