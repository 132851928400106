import React from 'react';

import GameBanner from '@molecules/GameBanner';
import links from '@constants/links';
import { IS_LG, IS_MD, IS_SM, IS_XL } from '@constants/platform';

interface Props {
  dimensions: { width: string | number; height: string | number };
  isPrincipal?: boolean;
}

const HalloweenBanner = ({ dimensions, isPrincipal }: Props) => {
  let image = links.halloweenMobileBanner;

  if (IS_SM) {
    image = links.halloweenTabletBanner;
  }

  if (IS_MD || IS_LG || IS_XL) {
    image = links.halloweenDesktopBanner;
  }

  const item = {
    id: 0,
    principalImage: links.halloweenMobileBanner,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    principalImage_web: image,
    link: links.avatarsGatoLink,
  };

  return <GameBanner item={item} dimentions={dimensions} ppal={isPrincipal} />;
};

export default HalloweenBanner;
