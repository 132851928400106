import React from 'react';
import { Pressable, View } from 'react-native';

import {
  useStore,
  toggleMenuModalHandler,
  toggleNotificationsModalHandler,
  notificationsModalOpenSelector,
} from '@store/index';
import { useMyProfile } from '@services/useMyProfile';
import { AvatarProfile } from '@atoms/AvatarProfile';

import { styles } from './styles';

const MenuButton = (): JSX.Element => {
  const { data: userProfile } = useMyProfile();
  // store
  const toggleMenu = useStore(toggleMenuModalHandler);

  const notificationsModalOpen = useStore(notificationsModalOpenSelector);
  const toggleNotificationsModal = useStore(toggleNotificationsModalHandler);
  // functions
  function handleMenuButton(): void {
    if (notificationsModalOpen) {
      toggleNotificationsModal();
    }

    toggleMenu();
  }

  // render
  return (
    <Pressable hitSlop={20} style={styles.menuButton} onPress={handleMenuButton}>
      <AvatarProfile
        isOnline={userProfile?.isOnline}
        customContainerStyle={styles.avatar}
        imageUrl={userProfile?.profileImage || ''}
        backgroundUrl={userProfile?.backgroundProfileImage || ''}
        offsetKitten={2}
        isInNavigationBar
      />
    </Pressable>
  );
};

export default MenuButton;
