import { StyleSheet } from 'react-native';
import { Colors } from  '@theme';
import { moderateScale } from '@utils/scaling';
import { isWebResponsive } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: isWebResponsive ? 0 : moderateScale(16),
  },
  title: {
    marginBottom: moderateScale(30),
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: moderateScale(8),
    justifyContent: 'center',
    alignItems: 'center'
  },
  item: {
    padding: moderateScale(16),
    borderRadius: moderateScale(12),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: Colors.blackTwo,
    alignItems: 'center',
    gap: moderateScale(10),
    width: isWebResponsive ? '90vw' : '15vw',
    height: '100px',
  },
  image: {
    width: '90px',
    height: '90px',
    resizeMode: 'cover',
    borderRadius: 16,
    overflow: 'hidden',
  },
  tag: {
    position: 'absolute',
    top: 0,
  },
  description: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  creator: {
    marginTop: moderateScale(6),
  },
  emptyContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lottie: {
    width: moderateScale(207),
  },
});
