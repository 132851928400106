import { CommonActions, useNavigation, useRoute } from '@react-navigation/core';
import { LinearGradient } from 'expo-linear-gradient';
import React, { FC, useEffect } from 'react';
import { Pressable, View } from 'react-native';

import Image from '@atoms/FastImage';
import { Colors } from '@atoms/CustomText';
import GameTag from '@atoms/GameTag';
import { isDesktop, isWebResponsive } from '@constants/platform';
import { authTokenSelector, setGameIdHandler, useStore } from '@store/index';
import useFeatureFlag, { gameQuestEventSelector } from '@store/featureFlag';
import NewGameTag from '@atoms/NewGameTag';
import calculateDiffDays from '@utils/daysOfDiff';
import { DAYS_TO_BE_CONSIDERED_NEW_GAME } from '@constants/game';

import { styles } from './styles';
import { GameBannerProps } from './types';

const GameBanner: FC<GameBannerProps> = ({ dimentions, item, title, tag, tagColor, tagTextColor, ppal, internalLink}) => {
  const navigation = useNavigation();
  const setGameId = useStore(setGameIdHandler);
  const isGGQ = useFeatureFlag(gameQuestEventSelector);
  const authToken = useStore(authTokenSelector);
  const route = useRoute();

  const dateExpirationTokenCookie: string = new Date().setDate(new Date().getDate() + 1).toString();

  function setCookie(): void {
    document.cookie = `token=${authToken}; expires=${dateExpirationTokenCookie}; domain=.gato.us; path=/;`;
  }

  const handlePress = () => {
    if (internalLink) {
      navigation.navigate('GameBuilder');
      return;
    }

    if (isGGQ && ppal && item.id === 0) {
      window.open(item.link, '_blank');
      return;
    }

    setGameId(item.id);

    const navigateAction = CommonActions.navigate({
      name: 'GamePage',
      params: {
        name: item.gameSlug,
        from: route.name === 'Explore' ? 'explore' : undefined,
      },
      key: `GamePage/${item.gameSlug}`,
    });
    navigation.dispatch(navigateAction);
  };

  const gameDateOfRelease = new Date(item?.createdDate?.toString() || '');

  useEffect(() => {
    setCookie();
  }, []);

  if (!item) return null;

  return (
    <Pressable onPress={handlePress} style={{ ...dimentions }}>
      <LinearGradient
        start={{ x: 1, y: 0 }}
        end={{ x: 1, y: 1 }}
        locations={[0.5, 1]}
        colors={['rgba(0,0,0,0)', ppal ? 'rgba(0,0,0,0.7)' : 'rgba(0,0,0,0.6)']}
        style={styles.linear}
      />
      <Image
        style={styles.bannerImage}
        source={{ uri: isDesktop && !isWebResponsive ? item.principalImage_web : item?.principalImage }}
      />
      {title ? (
        <View style={[styles.titleWrapper, { maxWidth: +dimentions.width - 25 }]}>{title}</View>
      ) : null}
      {tag ? (
        <GameTag
          color={tagColor as Colors}
          text={tag}
          textColor={tagTextColor as Colors}
          bigText={isDesktop && ppal}
        />
      ) : null}
      {gameDateOfRelease && calculateDiffDays(gameDateOfRelease) <= DAYS_TO_BE_CONSIDERED_NEW_GAME ? (
        <NewGameTag bigText={isDesktop && ppal} />
      ) : null}
    </Pressable>
  );
};

export default GameBanner;
