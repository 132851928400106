/* eslint-disable react/no-unstable-nested-components */

import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import { useFocusEffect } from '@react-navigation/core';
import { authTokenSelector, useStore } from '@store/index';

import { RootStackNavigator } from 'app/navigation/types';

import { styles } from './styles.web';

type GameBuilderPageProps = NativeStackScreenProps<RootStackNavigator, 'GameBuilder'>;

const GameBuilderPage = ({ route, navigation }: GameBuilderPageProps) => {
  // Refs
  // const mainViewRef = createRef<ScrollView>();

  // Hooks
  // const { getState } = useNavigation();
  const authToken = useStore(authTokenSelector);
  const headerAnimation = useSharedValue(0);
  const headerOpacityStyle = useAnimatedStyle(() => ({ opacity: headerAnimation.value }));
  // const i18n = useTranslations();
  // const countryISOCode = useCountryIsoCode();

  // // States
  // const language = getLanguage();
  // const { routes } = getState();

  const headerBackground = () => <Animated.View style={[headerOpacityStyle, styles.navbarBackground]} />;

  // Effects

  useEffect(() => {
    if (!authToken) {
      navigation.navigate('Login');
    }

    const dateExpirationTokenCookie: string = new Date().setDate(new Date().getDate() + 1).toString();

    document.cookie = `token=${authToken}; expires=${dateExpirationTokenCookie}; domain=.gato.us; path=/; SameSite=None; Secure`;
  }, [authToken]);

  useFocusEffect(() => {
    navigation.setOptions({
      headerTransparent: true,
      headerBackground,
      title: `GATO | Game Builder`,
    });
  });

  // render
  window.addEventListener('message', (e) => {
    const { action, data } = e.data;

    if (action === 'navigate-login') {
      navigation.navigate('Login');
    }

    if (action === 'navigate-gamepage') {
      navigation.navigate('GamePage', { game: data.id, name: data.slug });
    }

    if (action === 'play-game') {
      navigation.navigate('GamePage', { game: data.id, name: data.slug, autoStartGame: true });
    }
  });

  const url = 'https://gamebuilder.gato.us/index.html';

  return (
    <View style={{ position: 'relative', flex: 1 }}>
      <iframe
        title="gato-game-builder"
        src={url}
        allow="clipboard-read; clipboard-write"
        style={{ width: '100%', height: '100%', border: 0 }}
      />
    </View>
  );
};

export default GameBuilderPage;
