import { StyleSheet } from 'react-native';

import {
  isDesktop,
  isMobile,
  isWebResponsive,
  SCREEN_WIDTH as HW_SCREEN_WIDTH,
  WINDOW_WIDTH,
} from '@constants/platform';
import { Colors, Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';
import { SIZES } from 'app/theme/fonts';

const AVATAR_DIMENTIONS = moderateScale(42);
const SCREEN_WIDTH = isMobile ? HW_SCREEN_WIDTH : WINDOW_WIDTH;

export const styles = StyleSheet.create({
  desktopHeader: {
    width: '100%',
    alignItems: 'center',
    // Commented styles are for the header banner
    // height: 628 - (Spacing.SMALL + (isWebResponsive ? BANNER_SIZE.HEADER_BANNER_MOBILE.HEIGHT : 0)),
    height: 628,
  },
  navbarBackground: {
    backgroundColor: Colors.black,
    // Commented styles are for the header banner
    // height: 100 + (!isWebResponsive ? BANNER_SIZE.GAME_BANNER_LARGE_DESKTOP.HEIGHT + Spacing.SMALL : 0),
    height: isWebResponsive ? 60 : 80,
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
  },
});
