import React from 'react';

import TapIconBig from '@assets/svg/TapIconBig';
import SwipeIconBig from '@assets/svg/SwipeIconBig';
import ControlIconBig from '@assets/svg/ControlIconBig';
import SinglePlayerIcon from '@assets/svg/SinglePlayerIcon';
import MultiPlayerIcon from '@assets/svg/MultiPlayerIcon';
import MouseIconBig from '@assets/svg/MouseIconBig';
import KeyboardIconBig from '@assets/svg/KeyboardIconBig';
import MouseKeyboardIconBig from '@assets/svg/MouseKeyboardIconBig';
import { isMobile, isWebResponsive } from '@constants/platform';

export const FILTERS_TYPES_ICONS = {
  tap: (fill: string) => <TapIconBig fill={fill} />,
  swipe: (fill: string) => <SwipeIconBig fill={fill} />,
  gamepad: (fill: string) => <ControlIconBig fill={fill} />,
  mouse: (fill: string) => <MouseIconBig fill={fill} />,
  keyboard: (fill: string) => <KeyboardIconBig fill={fill} />,
  both: (fill: string) => <MouseKeyboardIconBig fill={fill} />,
  singleplayer: (fill: string) => <SinglePlayerIcon fill={fill} />,
  multiplayer: (fill: string) => <MultiPlayerIcon fill={fill} />,
};
export const CONTROLS_TYPES = isWebResponsive
  ? [
    { id: 'tap', name: 'Tap' },
    { id: 'swipe', name: 'Swipe' },
    { id: 'gamepad', name: 'Gamepad' },
  ]
  : [
    { id: 'mouse', name: 'Mouse' },
    { id: 'keyboard', name: 'Keyboard' },
    { id: 'both', name: 'Keyboard & Mouse' },
  ];
export const PLAYERS_TYPES = [
  { id: 1, textId: 'singleplayer', name: 'Single player' },
  { id: 2, textId: 'multiplayer', name: 'Multiplayer' },
];
export const SENSITIVE_MATERIAL = [{ id: 'no_violent_scenes', name: 'No violent scenes' }];

export const GGQ = [{ id: 'participates_in_ggq', name: 'Participates in GGQ' }];
export const GAME_BUILDER = [{ id: 'game_builder', name: 'Powered by GameBuilder' }];