import React from 'react';
import { View } from 'react-native';

import SvgUri from '@atoms/SvgUri';
import Checkbox from '@atoms/Checkbox';
import CustomText from '@atoms/CustomText';
import FilterCategoryItem, { FilterTypeI } from '@atoms/FilterCategoryItem';
import FilterItem from '@atoms/FilterItem';
import Switch from '@atoms/Switch';
import { useTranslations } from '@hooks/useTranslation';
import { CategoryData, FilterCategoryData } from '@interfaces/explore';
import { useGenres } from '@services/useGenres';
import { useGraphicStyles } from '@services/useGraphicStyles';
import { useMoods } from '@services/useMoods';
import { selectedFiltersSelector, setSelectedFiltersHandler, useStore } from '@store/index';
import { handleFilter } from '@utils/filtersHandler';
import { moderateScale } from '@utils/scaling';
import { isDesktop } from '@constants/platform';

import { CONTROLS_TYPES, GGQ, PLAYERS_TYPES, SENSITIVE_MATERIAL, GAME_BUILDER } from './constants';
import { styles } from './styles';
import GameQuestFilterLogo from '@assets/svg/GameQuestFilterLogo';
import GameBuilderFilterLogo from '@assets/svg/GameBuilderFilterLogo';
import useFeatureFlag, { gameQuestEventSelector } from '@store/featureFlag';
import { Colors } from '@theme';

const GameFilters = () => {
  const i18n = useTranslations();
  const selectedFilters = useStore(selectedFiltersSelector);
  const setSelectedFilters = useStore(setSelectedFiltersHandler);
  const isGGQ = useFeatureFlag(gameQuestEventSelector);

  const { data: genres } = useGenres();
  const { data: moods } = useMoods();
  const { data: graphicStyles } = useGraphicStyles();

  const handleFilterItem = (item: CategoryData, category: string) => {
    let newArr: FilterCategoryData[] = [];
    const filters = selectedFilters || [];

    if (item.id === 'game_builder') {
      setSelectedFilters(
        handleFilter(
          { ...item, category },
          filters.filter((i) => i.category === category)
        )
      );
      return;
    }

    if (selectedFilters?.some((i) => i.id === 'game_builder')) {
      setSelectedFilters([{ ...item, category }]);
      return;
    }

    if (category === 'players') {
      newArr = handleFilter(
        { ...item, category },
        filters.filter((i) => i.category !== category || (i.category === category && i.id === item.id))
      );
    } else {
      newArr = handleFilter({ ...item, category }, filters);
    }
    setSelectedFilters(newArr);
  };

  const getGGQFilters = () => {
    if (isGGQ) {
      return (
        <View style={[styles.filterItems, styles.controlItems]}>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: moderateScale(24) }}>
            <CustomText size="lmedium" align="left" color="greyFilter">
              {i18n.t('explore.participate_in')}
            </CustomText>
            <View style={{ marginLeft: 10 }}>
              <GameQuestFilterLogo />
            </View>
          </View>
          {GGQ.map((ggq, index) => (
            <Switch
              key={`ggq-${ggq.id + ggq.name + index}`}
              active={Boolean(
                selectedFilters?.filter((item) => 
                  item.id + item.category === `${ggq.id}participatesInGGQ`).length
              )}
              onSelect={() => handleFilterItem(ggq, 'participatesInGGQ')}
            />
          ))}
        </View>
      )
    }
  }

  const getGameBuilderFilter = () => (
    <View style={[styles.filterItems, styles.controlItems]}>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: moderateScale(24) }}>
        <CustomText size="lmedium" align="left" color="greyFilter">
          Powered By
        </CustomText>
        <View style={{ marginLeft: 10, width: '130px', height: '15px' }}>
          <GameBuilderFilterLogo width="100%" height="auto" />
        </View>
      </View>
      {GAME_BUILDER.map((ggb, index) => (
        <Switch
          key="game-builder-switch"
          active={Boolean(selectedFilters?.filter((item) => item.id === 'game_builder').length)}
          onSelect={() => handleFilterItem(ggb, 'isGameBuilder')}
        />
      ))}
    </View>
  );

  return (
    <>
      <View style={styles.filterCategory}>
        <View style={{ borderBottomColor: Colors.greyFour, borderBottomWidth: 1, paddingBottom: 8 }}>
          {getGameBuilderFilter()}
        </View>
        {getGGQFilters()}
        <CustomText size="lmedium" align="left" color="greyFilter">
          {i18n.t('explore.genres')}
        </CustomText>
        <View style={styles.filterItems}>
          {genres &&
            genres.map((genre, index) => {
              const isSelected = Boolean(
                selectedFilters?.filter((item) => item.id + item.category === `${genre.id}genresIds`).length
              );
              return (
                <FilterItem
                  key={`genre-${genre.id + genre.name + index}`}
                  item={genre}
                  onSelect={(item) => handleFilterItem(item, 'genresIds')}
                  active={isSelected}>
                  <CustomText
                    weight="interSemi"
                    size="medium"
                    color={isSelected ? 'limeGreen' : 'greyFilter'}>
                    {i18n.t(genre.translate)}
                  </CustomText>
                </FilterItem>
              );
            })}
        </View>
      </View>
      <View style={styles.filterCategory}>
        <CustomText size="lmedium" align="left" color="greyFilter">
          {i18n.t('explore.moods')}
        </CustomText>
        <View style={styles.filterItems}>
          {moods &&
            moods.map((mood, index) => {
              const isSelected = Boolean(
                selectedFilters?.filter((item) => item.id + item.category === `${mood.id}moodsIds`).length
              );
              return (
                <FilterItem
                  key={`mood-${mood.id + mood.name + index}`}
                  customStyle={{ borderRadius: moderateScale(12) }}
                  item={mood}
                  onSelect={(item) => handleFilterItem(item, 'moodsIds')}
                  active={isSelected}>
                  <View style={styles.mood}>
                    <SvgUri
                      uri={isSelected ? mood.miniatureImageSelected : mood.miniatureImage}
                      style={isDesktop ? styles.moodIcon : undefined}
                    />
                    <CustomText
                      customStyle={styles.moodText}
                      weight="interSemi"
                      size="medium"
                      color={isSelected ? 'limeGreen' : 'greyFilter'}>
                      {i18n.t(mood.translate)}
                    </CustomText>
                  </View>
                </FilterItem>
              );
            })}
        </View>
      </View>
      <View style={styles.filterCategory}>
        <CustomText size="lmedium" align="left" color="greyFilter">
          {i18n.t('explore.controls')}
        </CustomText>
        <View style={[styles.filterItems, styles.controlItems, styles.categoryItemsContainer]}>
          {CONTROLS_TYPES.map((control, index) => (
            <FilterCategoryItem
              key={`control-${control.id + control.name + index}`}
              item={control}
              active={Boolean(
                selectedFilters?.filter((item) => item.id + item.category === `${control.id}control`).length
              )}
              type={control.id as FilterTypeI}
              onSelect={(item) => handleFilterItem(item, 'control')}
            />
          ))}
        </View>
      </View>
      <View style={styles.filterCategory}>
        <CustomText size="lmedium" align="left" color="greyFilter">
          {i18n.t('explore.graphic_style')}
        </CustomText>
        <View style={[styles.filterItems, styles.controlItems]}>
          {graphicStyles &&
            graphicStyles.map((graphic, index) => (
              <Checkbox
                key={`graphic-${graphic.id + graphic.name + index}`}
                active={Boolean(
                  selectedFilters?.filter((item) => item.id + item.category === `${graphic.id}graphicIds`)
                    .length
                )}
                onSelect={(item) => handleFilterItem(item, 'graphicIds')}
                item={graphic}
              />
            ))}
        </View>
      </View>
      <View style={styles.filterCategory}>
        <CustomText size="lmedium" align="left" color="greyFilter">
          {i18n.t('explore.players')}
        </CustomText>
        <View style={[styles.filterItems, styles.controlItems, styles.categoryItemsContainer]}>
          {PLAYERS_TYPES.map((player, index) => (
            <FilterCategoryItem
              key={`players-${player.id + player.name + index}`}
              active={Boolean(
                selectedFilters?.filter((item) => item.id + item.category === `${player.id}players`).length
              )}
              item={player}
              type={player.textId as FilterTypeI}
              onSelect={(item) => handleFilterItem(item, 'players')}
            />
          ))}
        </View>
      </View>
      <View style={styles.filterCategory}>
        <CustomText size="lmedium" align="left" color="greyFilter">
          {i18n.t('explore.sensitive_material')}
        </CustomText>
        <View>
          {SENSITIVE_MATERIAL.map((material, index) => (
            <View
              key={`sensitive-${material.id + material.name + index}`}
              style={[styles.filterItems, styles.controlItems]}>
              <CustomText weight="interSemi" size="medium" color="greyFilter">
                {i18n.t(`explore.${material.id}`)}
              </CustomText>
              <Switch
                active={Boolean(
                  selectedFilters?.filter((item) => item.id + item.category === `${material.id}control`)
                    .length
                )}
                onSelect={() => handleFilterItem(material, 'sensitiveMaterial')}
              />
            </View>
          ))}
        </View>
      </View>
    </>
  );
};

export default GameFilters;
