import React from 'react';
import { View } from 'react-native';

import CustomText from '@atoms/CustomText';
import { isDesktop, isWebResponsive } from '@constants/platform';
import { useTranslations } from '@hooks/useTranslation';
import GatoLogo from '@molecules/GatoLogo';

import styles from './styles';

const LoginHeader = ({ showGameBuilderBanner }) => {
  const i18n = useTranslations();
  const textAlign = isDesktop && !isWebResponsive ? 'center' : 'left';

  return (
    <View>
      <GatoLogo style={styles.logoImage} />
      <CustomText weight="interBold" size="xlarge" align={textAlign}>
        {`${i18n.t('login.title_1')} `}
        <CustomText weight="interBold" size="xlarge" color="limeGreen">
          {i18n.t('login.title_2')}
        </CustomText>
      </CustomText>
      {showGameBuilderBanner ? (
        <CustomText customStyle={styles.description} weight="interRegular" size="xbig" align={textAlign}>
          {i18n.t('login.game_builder_message')}
        </CustomText>
      ) : (
        <CustomText customStyle={styles.description} weight="interRegular" size="xbig" align={textAlign}>
          {i18n.t('login.welcome_back')}
        </CustomText>
      )}
    </View>
  );
};

export default LoginHeader;
