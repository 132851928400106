import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'videojs-contrib-ads';
import 'videojs-ima';

import 'video.js/dist/video-js.css';
import { Pressable, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/core';
import Player from 'video.js/dist/types/player';

import { BackButtonVideoAd } from '@assets/svg/BackButtonVideoAd';
import { formatTime } from '@utils/time';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import Button from '@atoms/Button';
import { Advertising } from '@interfaces/gameDetail';
import useGAMVideoTag from '@hooks/useGAMVideoTag';
import { isFirefox } from '@utils/isBrowser';

import { styles } from './styles';
import { imaOptions, videoOptions } from './adVideoConfig';

interface AdVideoProps {
  handleBack: () => void;
  handlePlay: () => void;
  gatoDirectSaleVideo?: Advertising;
}

const MAX_ADS_SHOWN = 1;
const SKIP_BUTTON_DISPLAY_TIME = 10 * 1000; // 10 seconds

const LOG_TAG = '[ADVIDEO]: ';

const LOG = (...args: any[]) => {
  console.log(LOG_TAG, ...args);
};

const AdVideo = ({ handleBack, handlePlay, gatoDirectSaleVideo }: AdVideoProps) => {
  // Hooks
  const i18n = useTranslations();
  const navigation = useNavigation();

  // Refs
  const videoRef = useRef(null);
  const playerRef = useRef<Player | null>(null);
  const adCountRef = useRef<number>(0);
  const timeOutIdRef = useRef<number | null>(null);

  // States
  const [countdownTimer, setCountdownTimer] = useState<string>('00:00');
  const [countdownTimerSeconds, setCountdownTimerSeconds] = useState<number>(0);
  const [loadedDirectSale, setLoadedDirectSale] = useState<boolean>(false);
  const [shownSkipButton, setShownSkipButton] = useState<boolean>(false);
  const [isAdSkippable, setIsAdSkippable] = useState<boolean>(false);
  const gamTag = useGAMVideoTag();

  // States for IMA Plugin
  const [adManager, setAdManager] = useState<any>(null);
  const [adLoader, setAdLoader] = useState<any>(null);

  navigation.setOptions({ headerShown: false });

  imaOptions.adTagUrl = gamTag;

  // Functions
  const resetSkipButton = () => {
    setShownSkipButton(false);
    delaySkipButton();
  };

  const delaySkipButton = () => {
    if (timeOutIdRef.current) {
      window.clearTimeout(timeOutIdRef.current);
    }

    const showSkipButton = () => setShownSkipButton(true);
    timeOutIdRef.current = window.setTimeout(showSkipButton, SKIP_BUTTON_DISPLAY_TIME);
  };

  const adsManagerHandler = (response: any) => {
    const { adsManager } = response;
    setAdManager(adsManager);
  };

  const adsLoaderHandler = (response: any) => {
    const { adsLoader } = response;
    setAdLoader(adsLoader);
  };

  const nextAd = () => {
    LOG('🚀 nextAd');
    adCountRef.current += 1;
    const adsShownQuantity = adCountRef.current;

    if (adsShownQuantity >= MAX_ADS_SHOWN) {
      disposePlayer();
      handlePlay();
      return;
    }

    playerRef.current?.ima.changeAdTag(gamTag);
    playerRef.current?.ima.requestAds();
  };

  const disposePlayer = () => {
    playerRef.current?.dispose();
    playerRef.current = null;
  };

  const skipAd = () => {
    // if (loadedDirectSale) {
    //   disposePlayer();
    //   handlePlay();

    //   return;
    // }

    // nextAd();

    console.log("skipping ad...");
    disposePlayer();
    handlePlay();
  };

  const goBack = () => {
    disposePlayer();
    handleBack();
  };

  const clearTimeOut = () => {
    if (timeOutIdRef.current) {
      window.clearTimeout(timeOutIdRef.current);
    }
  };

  const errorAdHandler = (from: string) => (event: any) => {
    console.error(LOG_TAG, `🚨 AdError (from: ${from}):`, event?.error?.data);
    skipAd()
    // nextAd();
  };

  const loadedEventHandler = (from: string) => (event: any) => {
    LOG(`[${from}] 🔥 Loaded:`, event);
    adManager.start();
  };

  const logEventHandler = (from: string) => (event: any) => {
    LOG(`[${from}] 🔥 Event:`, event);
  };

  // Effects
  useEffect(() => {
    if (!isAdSkippable) return;

    clearTimeOut();
  }, [isAdSkippable]);

  // Initialize player
  useEffect(() => {
    if (playerRef.current || !gamTag) return;

    const videoElement = document.createElement('video-js');

    videoElement.classList.add('vjs-big-play-centered');
    videoElement.style.width = '100%';
    videoElement.style.height = '100%';
    videoElement.setAttribute('playsinline', '');
    videoRef.current?.appendChild(videoElement);

    if (gatoDirectSaleVideo?.direct_sale) {
      videoOptions.sources[0].src = gatoDirectSaleVideo.imageUrl;

      playerRef.current = videojs(videoElement, videoOptions, () => {
        videojs.log('Your player is ready!');
      });

      setLoadedDirectSale(true);
      delaySkipButton();

      return;
    }

    if (isFirefox()) {
      delaySkipButton();
    }

    playerRef.current = videojs(videoElement, videoOptions, () => {
      videojs.log('Your player is ready!');
    });

    // IMA Plugin setup
    imaOptions.adTagUrl = gamTag;
    playerRef.current.ima(imaOptions);
    playerRef.current.ima.controller.playerWrapper.vjsPlayer.on('readyforpreroll', () =>
      LOG('🚀 readyforpreroll ✅')
    );

    // Listeners
    playerRef.current.on('ads-manager', adsManagerHandler);
    playerRef.current.on('ads-loader', adsLoaderHandler);
  }, [gamTag]);

  // Ad Manager listener
  useEffect(() => {
    if (adManager) {
      LOG('🚀 adManager:', adManager);
      setIsAdSkippable(adManager?.j?.data?.skippable);

      // Listener
      adManager.addEventListener('complete', skipAd);
      adManager.addEventListener('skip', skipAd);
      adManager.addEventListener('adError', errorAdHandler('adManager'));
      adManager.addEventListener('adBreakFetchError', logEventHandler('adBreakFetchError'));
      adManager.addEventListener('loaded', loadedEventHandler('loaded'));
      adManager.addEventListener('log', logEventHandler('log'));
      adManager.addEventListener('start', logEventHandler('start'));
      adManager.addEventListener('adCanPlay', logEventHandler('adCanPlay'));
      adManager.addEventListener('pause', logEventHandler('pause'));
    }
  }, [adManager]);

  // Ad Loader listener
  useEffect(() => {
    if (adLoader) {
      adLoader.addEventListener('adError', errorAdHandler('adLoader'));
      adLoader.addEventListener('adsManagerLoaded', logEventHandler('adsManagerLoaded'));
    }
  }, [adLoader]);

  // Get remaining time
  useEffect(() => {
    let timerId: any = null;
    if (loadedDirectSale || adManager) {
      timerId = setInterval(() => {
        const timeRemaining = loadedDirectSale
          ? playerRef.current?.remainingTimeDisplay()
          : adManager.getRemainingTime();

        const formattedTimeRemaining = formatTime(timeRemaining);

        setCountdownTimerSeconds(timeRemaining);
        setCountdownTimer(formattedTimeRemaining);
      }, 1000);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [loadedDirectSale, adManager]);

  useEffect(() => {
    if (loadedDirectSale && countdownTimerSeconds <= 0) {
      handlePlay();
    }
  }, [countdownTimerSeconds]);

  return (
    <div data-vjs-player style={styles.container}>
      <AdTopBar handleBack={goBack} />
      <RemainingTime remainingTime={countdownTimer} />
      <div ref={videoRef} style={styles.video} />
      {shownSkipButton && (
        <Button customStyle={styles.footerButton} customTextStyle={styles.footerButtonText} onPress={skipAd}>
          {i18n.t('game_page.skip_ad')}
        </Button>
      )}
    </div>
  );
};

interface AdTopBarProps {
  handleBack: () => void;
}

const AdTopBar = ({ handleBack }: AdTopBarProps) => {
  const { top } = useSafeAreaInsets();
  return (
    <View style={[styles.headerTop, { top: top + 20 }]}>
      <Pressable onPress={handleBack}>
        <View>
          <BackButtonVideoAd />
        </View>
      </Pressable>
    </View>
  );
};

interface RemainingTimeProps {
  remainingTime: string;
}

const RemainingTime = ({ remainingTime }: RemainingTimeProps) => {
  // const i18n = useTranslations();
  const { top } = useSafeAreaInsets();
  // const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <>
      <View style={[styles.content, { top: top + 24 }]} pointerEvents="none">
        {/* <View style={[styles.row, styles.rowheader]}>
          <View style={styles.row}>
            <Pressable onPress={() => setShowTooltip((prev) => !prev)}>
              <View style={styles.tooltipCta}>
                <CustomText customStyle={styles.tooltipUi} weight="interSemi" color="white">
                  {i18n.t('game_page.tooltip_button_title')}
                </CustomText>
              </View>
            </Pressable>
            <Pressable onPress={() => setShowTooltip((prev) => !prev)}>
              <HelpVideoAdIcon fill="rgba(255,255,255,.5)" style={styles.ml6} />
            </Pressable>
          </View>
        </View> */}
        <View style={styles.seconds}>
          <CustomText align="left" weight="interSemi" color="white">
            {remainingTime}
          </CustomText>
        </View>
      </View>
      {/* {showTooltip && (
        <Pressable style={[styles.tooltipContainer, { top: top + 80 }]} onPress={() => setShowTooltip(false)}>
          <>
            <View style={styles.tooltipIndicator} />
            <View style={styles.row}>
              <HelpVideoAdIcon fill="#E5F68C" style={styles.mr6} />
              <CustomText weight="interSemi" align="left" color="white">
                {i18n.t('game_page.tooltip_title')}
              </CustomText>
            </View>
            <CustomText
              customStyle={styles.tooltipDescription}
              weight="interMedium"
              align="left"
              size="msmall">
              {i18n.t('game_page.tooltip_description')}
            </CustomText>
          </>
        </Pressable>
      )} */}
    </>
  );
};

export default AdVideo;
