import * as React from "react"
import Svg, { Path, Defs, LinearGradient, Stop, SvgProps } from "react-native-svg"

function GameBuilderFilterLogo({ fill, ...props }: SvgProps) {
  return (
    <Svg
      width={1239}
      height={112}
      viewBox="0 0 1239 112"
      fill="none"
      {...props}
    >
      <Path d="M94.47 14.5886H101.74V22.0157H94.47V29.4428H50.87V81.4119H72.67V66.568H65.4V59.1409H58.13V51.7138H65.4V44.2867H94.47V51.7138H101.74V96.2558H94.47V103.683H87.2V111.11H14.53V103.683H7.26V96.2558H0V22.0157H7.27V29.4428H14.54V22.0157H21.81V14.5886H14.54V7.1615H94.48V14.5886H94.47Z" fill="#F5FF00"/>
      <Path d="M210.73 29.4325H218V103.673H210.73V111.1H196.2V103.673H188.93V59.1306H167.13V103.673H159.86V111.1H123.52V103.673H116.25V22.0054H123.52V29.4325H130.79V22.0054H138.06V14.5783H130.79V7.15125H196.19V14.5783H203.46V22.0054H210.73V29.4325ZM167.13 44.2764H188.93V29.4325H167.13V44.2764Z" fill="#F5FF00"/>
      <Path d="M334.27 14.5885H341.54V103.673H334.27V111.1H312.47V103.673H305.2V81.4017H297.93V88.8287H290.66V81.4017H283.39V103.673H276.12V111.1H239.78V103.673H232.51V14.5885H239.78V29.4325H247.05V22.0054H254.32V14.5783H239.79V7.15125H276.13V14.5783H283.4V44.2764H290.67V51.7035H297.94V44.2764H305.21V14.5783H312.48V7.15125H334.28V14.5783L334.27 14.5885Z" fill="#F5FF00"/>
      <Path d="M450.54 14.5886H457.81V22.0157H450.54V29.4428H406.94V44.2867H450.54V51.7138H457.81V59.1409H450.54V66.568H406.94V81.4119H450.54V88.839H457.81V103.683H450.54V111.11H363.34V103.683H356.07V14.5988H363.34V29.4428H370.61V22.0157H377.88V14.5886H363.35V7.1615H450.55V14.5886H450.54Z" fill="#F5FF00"/>
      <Path d="M95.47 7.4371H102.74V14.8642H95.47V22.2913H51.87V74.2604H73.67V59.4165H66.4V51.9894H59.13V44.5623H66.4V37.1352H95.47V44.5623H102.74V89.1044H95.47V96.5314H88.2V103.959H15.53V96.5314H8.26V89.1044H1V14.8642H8.27V22.2913H15.54V14.8642H22.81V7.4371H15.54V0.0100098H95.48V7.4371H95.47Z" fill="#9747FF"/>
      <Path d="M211.73 22.2812H219V96.5214H211.73V103.949H197.2V96.5214H189.93V51.9794H168.13V96.5214H160.86V103.949H124.52V96.5214H117.25V14.8542H124.52V22.2812H131.79V14.8542H139.06V7.42709H131.79V0H197.19V7.42709H204.46V14.8542H211.73V22.2812ZM168.13 37.1252H189.93V22.2812H168.13V37.1252Z" fill="#9747FF"/>
      <Path d="M335.27 7.43731H342.54V96.5214H335.27V103.949H313.47V96.5214H306.2V74.2504H298.93V81.6775H291.66V74.2504H284.39V96.5214H277.12V103.949H240.78V96.5214H233.51V7.43731H240.78V22.2812H248.05V14.8542H255.32V7.42709H240.79V0H277.13V7.42709H284.4V37.1252H291.67V44.5523H298.94V37.1252H306.21V7.42709H313.48V0H335.28V7.42709L335.27 7.43731Z" fill="#9747FF"/>
      <Path d="M451.54 7.4371H458.81V14.8642H451.54V22.2913H407.94V37.1352H451.54V44.5623H458.81V51.9894H451.54V59.4165H407.94V74.2604H451.54V81.6875H458.81V96.5314H451.54V103.959H364.34V96.5314H357.07V7.44731H364.34V22.2913H371.61V14.8642H378.88V7.4371H364.35V0.0100098H451.55V7.4371H451.54Z" fill="#9747FF"/>
      <Path d="M604.861 25.3928H612.002V53.9717H604.861V61.1124H612.002V96.832H604.861V103.973H597.721V111.114H519.141V103.973H512V18.2521H519.141V32.5336H526.282V25.3928H533.422V18.2521H519.141V11.1113H597.721V18.2521H604.861V25.3928ZM583.439 32.5493H562.001V46.8309H583.439V32.5493ZM583.439 82.5504V68.2689H562.001V82.5504H583.439Z" fill="#F5FF00"/>
      <Path d="M717.676 18.2521H724.703V96.832H717.676V103.973H710.648V111.114H640.343V103.973H633.316V96.832H626.288V18.2521H633.316V32.5336H640.343V25.3928H647.371V18.2521H633.316V11.1113H668.468V18.2521H675.496V82.5504H689.551V18.2521H696.578V11.1113H717.676V18.2521Z" fill="#F5FF00"/>
      <Path d="M782.53 18.2521H789.784V103.973H782.53V111.114H746.243V103.973H738.989V18.2521H746.243V32.5336H753.497V25.3928H760.751V18.2521H746.243V11.1113H782.53V18.2521Z" fill="#F5FF00"/>
      <Path d="M889.056 89.6912H896.135V103.973H889.056V111.114H811.149V103.973H804.07V18.2521H811.149V32.5336H818.229V25.3928H825.309V18.2521H811.149V11.1113H846.563V18.2521H853.642V82.5504H889.056V89.6912Z" fill="#F5FF00"/>
      <Path d="M1003.28 32.5336H1010.42V96.832H1003.28V103.973H996.142V111.114H917.562V103.973H910.421V18.2521H917.562V32.5336H924.703V25.3929H931.844V18.2521H917.562V11.1113H989.001V18.2521H996.142V25.3929H1003.28V32.5336ZM974.72 39.6744V32.5336H960.438V82.5347H974.72V75.394H981.861V39.6744H974.72Z" fill="#F5FF00"/>
      <Path d="M1116.1 18.2835H1123.12V25.4243H1116.1V32.5651H1073.92V46.8466H1116.1V53.9874H1123.12V61.1282H1116.1V68.2689H1073.92V82.5504H1116.1V89.6912H1123.12V103.973H1116.1V111.114H1031.74V103.973H1024.71V18.2521H1031.74V32.5336H1038.76V25.3929H1045.79V18.2521H1031.74V11.1113H1116.1V18.2521V18.2835Z" fill="#F5FF00"/>
      <Path d="M1230.27 25.3771H1237.41V53.9559H1230.27V61.0967H1223.13V68.2374H1230.27V75.3782H1237.41V103.957H1230.27V111.098H1215.99V103.957H1208.85V96.8163H1201.7V89.6755H1194.56V82.5347H1187.42V103.973H1180.28V111.114H1144.55V103.973H1137.41V18.2521H1144.55V32.5336H1151.69V25.3929H1158.84V18.2521H1144.55V11.1113H1223.14V18.2521H1230.29V25.3929L1230.27 25.3771ZM1208.83 32.5336H1187.39V46.8152H1208.83V32.5336Z" fill="#F5FF00"/>
      <Path d="M606.449 14.2815H613.589V42.8603H606.449V50.0011H613.589V85.7207H606.449V92.8614H599.308V100.002H520.728V92.8614H513.587V7.14076H520.728V21.4223H527.869V14.2815H535.009V7.14076H520.728V0H599.308V7.14076H606.449V14.2815ZM585.026 21.438H563.588V35.7196H585.026V21.438ZM585.026 71.4391V57.1576H563.588V71.4391H585.026Z" fill="#9747FF"/>
      <Path d="M719.263 7.14076H726.29V85.7207H719.263V92.8614H712.235V100.002H641.93V92.8614H634.903V85.7207H627.875V7.14076H634.903V21.4223H641.93V14.2815H648.958V7.14076H634.903V0H670.055V7.14076H677.083V71.4391H691.138V7.14076H698.165V0H719.263V7.14076Z" fill="#9747FF"/>
      <Path d="M784.117 7.14076H791.371V92.8614H784.117V100.002H747.831V92.8614H740.576V7.14076H747.831V21.4223H755.085V14.2815H762.339V7.14076H747.831V0H784.117V7.14076Z" fill="#9747FF"/>
      <Path d="M890.643 78.5799H897.723V92.8614H890.643V100.002H812.737V92.8614H805.657V7.14076H812.737V21.4223H819.816V14.2815H826.896V7.14076H812.737V0H848.15V7.14076H855.23V71.4391H890.643V78.5799Z" fill="#9747FF"/>
      <Path d="M1004.87 21.4223H1012.01V85.7206H1004.87V92.8614H997.729V100.002H919.149V92.8614H912.009V7.14076H919.149V21.4223H926.29V14.2815H933.431V7.14076H919.149V0H990.588V7.14076H997.729V14.2815H1004.87V21.4223ZM976.307 28.5631V21.4223H962.025V71.4234H976.307V64.2826H983.448V28.5631H976.307Z" fill="#9747FF"/>
      <Path d="M1117.68 7.17222H1124.71V14.313H1117.68V21.4538H1075.5V35.7353H1117.68V42.876H1124.71V50.0168H1117.68V57.1576H1075.5V71.4391H1117.68V78.5799H1124.71V92.8614H1117.68V100.002H1033.32V92.8614H1026.3V7.14076H1033.32V21.4223H1040.35V14.2815H1047.38V7.14076H1033.32V0H1117.68V7.14076V7.17222Z" fill="#9747FF"/>
      <Path d="M1231.86 14.2658H1239V42.8446H1231.86V49.9853H1224.72V57.1261H1231.86V64.2669H1239V92.8457H1231.86V99.9864H1217.57V92.8457H1210.43V85.7049H1203.29V78.5641H1196.15V71.4234H1189.01V92.8614H1181.86V100.002H1146.14V92.8614H1139V7.14076H1146.14V21.4223H1153.28V14.2815H1160.42V7.14076H1146.14V0H1224.73V7.14076H1231.87V14.2815L1231.86 14.2658ZM1210.42 21.4223H1188.98V35.7038H1210.42V21.4223Z" fill="#9747FF"/>
    </Svg>
  )
}

export default GameBuilderFilterLogo;
