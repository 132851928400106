import React, { useEffect, useState } from 'react';
import { Pressable, View } from 'react-native';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { AxiosError } from 'axios';
import { CompositeScreenProps } from '@react-navigation/core';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import toast from '@utils/toast';
import FilterIcon from '@assets/svg/FilterIcon';
import CustomText from '@atoms/CustomText';
import { isDesktop, isMobile, isWebResponsive, IS_SMALL_DEVICE } from '@constants/platform';
import { useTranslations } from '@hooks/useTranslation';
import GameBanner from '@molecules/GameBanner';
import { Layout } from '@organisms/Layout';
import { useExploreGames } from '@services/useExploreGames';
import { useGenres } from '@services/useGenres';
import { useGraphicStyles } from '@services/useGraphicStyles';
import { useMoods } from '@services/useMoods';
import {
  selectedFiltersSelector,
  setOpenFilterHandler,
  setSelectedFiltersHandler,
  setTotalGamesHandler,
  totalFiltersSelector,
  useStore,
} from '@store/index';
import { useDebounce } from '@hooks/useDebounce';
import ExploreEmptyIcon from '@assets/svg/ExploreEmptyIcon';
import GoBackButton from '@molecules/GoBackButton';
import GameFilters from '@organisms/GameFilters';
import Button from '@atoms/Button';
import { Colors } from '@theme';

import { MainNavigatorParamList, RootStackNavigator } from '../../../navigation/types';

import { Loading } from './loading';
import { styles } from './styles';
import { BANNER_DIMENTIONS } from './constants';
import Search from '@atoms/Search';
import { moderateScale } from '@utils/scaling';

type ExploreProps = CompositeScreenProps<
  | BottomTabScreenProps<MainNavigatorParamList, 'Explore'>
  | NativeStackScreenProps<MainNavigatorParamList, 'Explore'>,
  NativeStackScreenProps<RootStackNavigator>
>;

const Explore = ({ route, navigation }: ExploreProps) => {
  const fromDetail = route?.params?.fromDetail;
  const [comingFromGamePage, setComingFromGamePage] = useState(false);

  const [filtersApplied, setFiltersApplied] = useState('');
  const i18n = useTranslations();

  const setOpenFilter = useStore(setOpenFilterHandler);
  const setTotalGames = useStore(setTotalGamesHandler);
  const totalFilters = useStore(totalFiltersSelector);
  const selectedFilters = useStore(selectedFiltersSelector);
  const exploreGames = useExploreGames(isWebResponsive);
  const setSelectedFilters = useStore(setSelectedFiltersHandler);
  const noResultsFilter = (exploreGames.data?.length === 0 || !exploreGames.data)

  const debouncedFiltersApplied = useDebounce(filtersApplied, 1000);
  useGenres();
  useMoods();
  useGraphicStyles();

  const handlePressFilter = () => {
    setOpenFilter(true);
  };

  const handleClearFilters = () => setSelectedFilters([]);

  useEffect(() => { if (!fromDetail) handleClearFilters() }, [route])

  const updateGames = () => {
    if (!debouncedFiltersApplied && comingFromGamePage) return;

    exploreGames.mutate(debouncedFiltersApplied, {
      onSuccess: (data) => {
        const total = data.length;
        setTotalGames(total);
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          const message = err?.response?.data.message;
          toast.danger({ title: message, icon: 'error' });
          setTotalGames(0);
        }
      },
    });
  };

  const handleGoBack = () => {
    if (fromDetail) {
      navigation.navigate('GamePage', { game: fromDetail, from: 'explore' });
    } else {
      navigation.goBack();
    }
  };

  useEffect(() => {
    updateGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFiltersApplied]);

  useEffect(() => {
    if (selectedFilters) {
      const filterSeparator = '&';
      let appliedFilters = '';
      const genresFilters = selectedFilters.filter((item) => item.category === 'genresIds');
      const moodsFilters = selectedFilters.filter((item) => item.category === 'moodsIds');
      const controlFilters = selectedFilters.filter((item) => item.category === 'control');
      const graphicFilters = selectedFilters.filter((item) => item.category === 'graphicIds');
      const playersFilters = selectedFilters.filter((item) => item.category === 'players');
      const sensitiveMaterialFilters = selectedFilters.filter(
        (item) => item.category === 'sensitiveMaterial'
      );
      const participatesInGGQFilters = selectedFilters.filter(
        (item) => item.category === 'participatesInGGQ'
      );

      const isGameBuilderFilter = selectedFilters.filter((item) => item.category === 'isGameBuilder');

      if (genresFilters.length) {
        const genreText = genresFilters.map((genre) => genre.id).toString();
        appliedFilters += `genresIds=${genreText}${filterSeparator}`;
      }
      if (moodsFilters.length) {
        const moodText = moodsFilters.map((mood) => mood.id).toString();
        appliedFilters += `moodsIds=${moodText}${filterSeparator}`;
      }
      if (controlFilters.length) {
        const controlText = controlFilters
          .map((control) => typeof control.id === 'string' && `'${control.id.toUpperCase()}'`)
          .toString();
        appliedFilters += `control=${controlText}${filterSeparator}`;
      }
      if (graphicFilters.length) {
        const graphicText = graphicFilters.map((graphic) => graphic.id).toString();
        appliedFilters += `graphicIds=${graphicText}${filterSeparator}`;
      }
      if (playersFilters.length) {
        const playerText = playersFilters.map((player) => player.id).toString();
        appliedFilters += `players=${playerText}${filterSeparator}`;
      }
      if (sensitiveMaterialFilters.length) {
        const sensitiveMaterialBoolean = false;
        appliedFilters += `sensitiveMaterial=${sensitiveMaterialBoolean}${filterSeparator}`;
      }
      if (participatesInGGQFilters.length) {
        const participatesInGGQBoolean = true;
        appliedFilters += `participatesInGGQ=${participatesInGGQBoolean}${filterSeparator}`;
      }

      if (isGameBuilderFilter.length) {
        const isGameBuilderBoolean = true;
        appliedFilters = `ggb=${isGameBuilderBoolean}`;
      }
      appliedFilters.slice(-1);
      setFiltersApplied(appliedFilters);
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (fromDetail) {
      setComingFromGamePage(true);
    }
  }, [fromDetail]);

  return (
    <Layout padding={isWebResponsive ? false : true} tabOffset={IS_SMALL_DEVICE ? 150 : 130} desktopFooter showScrollIndicator={isMobile}>
      <View style={styles.title}>
        {fromDetail && isMobile ? (
          <View style={styles.goBack}>
            <GoBackButton goBackAction={handleGoBack} />
          </View>
        ) : null}
        {isMobile && (
          <CustomText size="mbig" align="left" weight="interBold">
            {i18n.t('explore.find_your_game')}
          </CustomText>
        )}
      </View>
      <View style={isDesktop && styles.desktopLayoutContainer}>

        {isDesktop && !isWebResponsive &&
          <View style={styles.desktopFiltersContainer}>

            <View style={styles.filtersHeader}>
              <View style={styles.border}>
                <CustomText size="big" weight="interSemi">
                  {i18n.t('explore.filters')}
                </CustomText>
                <Button onPress={handleClearFilters} secondary disabled={!selectedFilters?.length}>
                  {i18n.t('explore.clear_all')}
                </Button>
              </View>
            </View>
            <GameFilters />
          </View>
        }

        {/* {isDesktop && <View style={styles.desktopVerticalSeparator} />} */}
        <View style={isDesktop && styles.desktopResultsContainer}>
          {exploreGames.isLoading ? <Loading /> : null}
          <View style={styles.skeletonContainer}>
            {isDesktop && !exploreGames.isLoading && (
              <View style={styles.searchGame}>
                <CustomText size="xbig" align="left" weight='interBold' customStyle={isWebResponsive ? styles.responsiveTitle : undefined}>
                  {i18n.t('explore.find_your_game')}
                </CustomText>
                {isWebResponsive && (
                  <View style={styles.search}>
                    <Search />
                  </View>
                )}
              </View>
            )}
            {isDesktop && isWebResponsive && !exploreGames.isLoading && (
              <View style={[{ width: "100%", marginBottom: moderateScale(16), zIndex: -1000 }]}>
                <Pressable onPress={handlePressFilter} style={styles.filterButton}>
                  <FilterIcon />
                  <CustomText weight="interBold" customStyle={styles.filterText}>
                    {i18n.t('explore.filters')}
                  </CustomText>
                  {totalFilters ? (
                    <View style={styles.filterTotal}>
                      <CustomText>{totalFilters}</CustomText>
                    </View>
                  ) : null}
                </Pressable>
              </View>
            )}

            {noResultsFilter && !exploreGames.isLoading ? (
              <View style={[styles.emptyState, isDesktop && styles.emptyStateDesktop]}>
                <ExploreEmptyIcon />
                <CustomText customStyle={styles.emptyTitle} color="greySix" size="xbig">
                  {i18n.t('explore.empty_title')}
                </CustomText>
                <CustomText color="greySix" size="big">
                  {i18n.t('explore.empty_description')}
                </CustomText>
              </View>
            ) : null}

            {exploreGames.data?.map((item, index) => (
              <View key={`${item.id + item.name + index}`} style={styles.skeletonItem}>
                <GameBanner
                  title={
                    <CustomText
                      align="left"
                      numberOfLines={2}
                      size={(isMobile || isWebResponsive) ? 'medium' : 'lmedium'}
                      weight="interSemi">
                      {item.name}
                    </CustomText>
                  }
                  item={item}
                  dimentions={BANNER_DIMENTIONS}
                  tag={isDesktop ? i18n.t(item.genre.translate) : undefined}
                  tagColor={Colors.limeGreen}
                  tagTextColor={Colors.black}
                />
              </View>
            ))}
          </View>
        </View>
      </View>
    </Layout>
  );
};

export default Explore;
