import React, { FC, useState } from 'react';
import { ImageBackground, Image, View, ViewStyle, Platform, Pressable, Modal } from 'react-native';

import FastImage from '@atoms/FastImage';

import { styles } from './styles';
import { MotiView } from 'moti';
import { ResizeMode } from 'react-native-fast-image';

import { useGetUserProfile } from '@services/useGetUserProfile';

interface AvatarProfileProps {
  imageUrl: string;
  backgroundUrl?: string;
  customContainerStyle?: ViewStyle;
  offsetKitten?: number;
  fromSettings?: boolean;
  imageUrlPlaceholder?: string;
  enableAvatarModal?: boolean;
  isOnline?: boolean;
  isInNavigationBar?: boolean;
}

const CustomImage = Platform.OS !== 'web' ? FastImage : Image;

interface IAvatarModalProps extends AvatarProfileProps {
  open?: boolean;
  handleModal(): void;
  resizeMode: ResizeMode;
}

export const AvatarModal: FC<IAvatarModalProps> = ({ open, imageUrl, handleModal, backgroundUrl }) => {
  // render
  return (
    <Modal transparent visible={open} animationType="fade">
      <Pressable onPress={handleModal} style={styles.avatarModalContainer}>
        <MotiView
          style={styles.avatarAnimatedView}
          from={{ opacity: 0, transform: [{ scale: 0 }] }}
          animate={{ opacity: 1, transform: [{ scale: 1 }] }}
          transition={{ type: 'spring' }}
          exit={{ opacity: 0, transform: [{ scale: 0 }] }}>
          <ImageBackground style={styles.modalCatBackground} source={{ uri: backgroundUrl }}>
            <CustomImage
              resizeMode={!imageUrl.includes('/avatar/') ? 'cover' : 'contain'}
              style={styles.modalCat}
              source={{ uri: imageUrl, priority: 'high' }}
            />
          </ImageBackground>
        </MotiView>
      </Pressable>
    </Modal>
  );
};

export const AvatarProfile = ({
  imageUrl,
  backgroundUrl,
  customContainerStyle,
  offsetKitten = 15,
  fromSettings,
  enableAvatarModal = false,
  isOnline,
  isInNavigationBar,
}: AvatarProfileProps): JSX.Element => {
  // states
  const [openAvatarModal, setOpenAvatarModal] = useState<boolean>(false);
  // functions
  function handleModal(): void {
    setOpenAvatarModal((prev) => !prev);
  }
  // constants
  const resizeImage: ResizeMode = !imageUrl.includes('/avatar/') ? 'cover' : 'contain';

  const marginOnAvatar: Pick<ViewStyle, 'marginTop'> = {
    marginTop:
      imageUrl.includes('/profile/profile-default.png') || !imageUrl.includes('/avatar/') ? 0 : offsetKitten
  };

  // render
  if (fromSettings)
    return (
      <View style={[styles.container, customContainerStyle]}>
        <CustomImage
          resizeMode={resizeImage}
          style={[styles.cat, marginOnAvatar]}
          source={{ uri: imageUrl, priority: 'high' }}
        />
      </View>
    );

  return (
    <View style={{ position: 'relative' }}>
      <Pressable
        style={[styles.container, customContainerStyle, isOnline && styles.online]}
        onPress={handleModal}
        disabled={!enableAvatarModal}>
        <ImageBackground style={styles.background} source={{ uri: backgroundUrl }}>
          <CustomImage
            resizeMode={resizeImage}
            style={[styles.cat, marginOnAvatar]}
            source={{ uri: imageUrl, priority: 'high' }}
          />
        </ImageBackground>
      </Pressable>
      <AvatarModal
        imageUrl={imageUrl}
        open={openAvatarModal}
        handleModal={handleModal}
        resizeMode={resizeImage}
        backgroundUrl={backgroundUrl}
      />
      {isOnline ? (
        <View style={[styles.onlineIndicator, isInNavigationBar && styles.isInNavigationBar]} />
      ) : null}
    </View>
  );
};
