import React from 'react';
import { View } from 'react-native';

import CustomText from '@atoms/CustomText';
import { Ghost } from '@phosphor-icons/react';

import Verified from '../../../assets/svg/Verified';

import { styles } from './styles';

interface UserProfileInfoProps {
  fullName: string;
  userName: string;
  userType: 'PLAYER' | 'CREATOR';
  bio: string;
}

export const UserProfileInfo = ({ fullName, userName, userType, bio }: UserProfileInfoProps) => (
  <View style={styles.container}>
    <View style={styles.fullNameContainer}>
      <CustomText weight="interSemi" size="mlarge" numberOfLines={1} customStyle={{ maxWidth: '20ch' }}>
        {fullName}
      </CustomText>
      {userType === 'CREATOR' && <Verified />}
    </View>
    <View style={styles.typeContainer}>
      <CustomText color="purple" weight="interSemi">
        {`@${userName}`}
      </CustomText>
      <View style={styles.userType}>
        <CustomText customStyle={styles.userTypeText} weight="interMedium">
          {userType.toLocaleLowerCase()}
        </CustomText>
      </View>
    </View>
    {bio && (
      <CustomText customStyle={styles.bio} color="greySix" weight="interSemi">
        {bio}
      </CustomText>
    )}
    {/* <View>
      <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 8, backgroundColor: 'rgb(128 128 128 / 34%)', borderRadius: '50%', width: '60px' }}>
        <Ghost weight="fill" color="#9746ff" size={40} />
      </View>
    </View> */}
  </View>
);
